import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import './memeraids.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import meme1 from '../../images/memes/meme-1.png'
import meme2 from '../../images/memes/meme-2.png'
import meme3 from '../../images/memes/meme-3.png'
import meme4 from '../../images/memes/meme-4.png'
import meme5 from '../../images/memes/meme-5.png'
import meme6 from '../../images/memes/meme-6.png'
import meme7 from '../../images/memes/meme-7.png'
import meme8 from '../../images/memes/meme-8.png'
import meme9 from '../../images/memes/meme-9.png'
import meme10 from '../../images/memes/meme-10.png'
import meme11 from '../../images/memes/meme-11.png'
import meme12 from '../../images/memes/meme-12.png'

const shuffleIcon = <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.2918" cy="15.2918" r="15.1664" fill="#FFE600" stroke="black" strokeWidth="0.250685"/>
<circle cx="15.2918" cy="15.2918" r="13.6623" fill="#F6A12E" stroke="black" strokeWidth="0.250685"/>
<path d="M25 14.9043L18.707 21L17.2038 19.1686L21.3822 15.0137L17 10.9408L18.6306 9L25 14.9043Z" fill="#260900"/>
<path d="M6 15.0957L12.293 9L13.7962 10.8314L9.61784 14.9863L14 19.0592L12.3694 21L6 15.0957Z" fill="#260900"/>
</svg>

const arrowIcon = <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.2918" cy="15.2918" r="15.1664" fill="#FFE600" stroke="black" strokeWidth="0.250685"/>
<circle cx="15.2918" cy="15.2918" r="13.6623" fill="#F6A12E" stroke="black" strokeWidth="0.250685"/>
<path d="M20.7856 15.012L13.1076 21.944L11.2736 19.8613L16.3715 15.1364L11.0249 10.5047L13.0143 8.29767L20.7856 15.012Z" fill="#260900"/>
</svg>

const MemeRaids = () => {
  const widthRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [random, setRandom] = useState(0)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useLayoutEffect(() => {
    setWidth(widthRef.current.offsetWidth)
  })

  const shuffleMeme = () => {
    const randomNumber = Math.floor(Math.random() * 12)
    setRandom(randomNumber)
  }

  const memes = [
    {
      link: meme1
    },
    {
      link: meme2
    },
    {
      link: meme3
    },
    {
      link: meme4
    },
    {
      link: meme5
    },
    {
      link: meme6
    },
    {
      link: meme7
    },
    {
      link: meme8
    },
    {
      link: meme9
    },
    {
      link: meme10
    },
    {
      link: meme11
    },
    {
      link: meme12
    }
  ]
  return (
  <div id="memeraids" ref={widthRef} className="memeSection">
      <div className="memeRow">
        <h2>MemeRaids</h2>
        <div className="memeBlocksContainer">
          <div className='bgForMeme' style={{ backgroundImage: `url(${memes[random].link})` }}>
            {/* {memes.map((meme, index) => (
              random === index
                ? <img key={index} src={meme.link} className="memeraidsFish" />
                : null
            ))} */}
          </div>
        </div>
        <div className='memeButtonsWrap'>
              <button onClick={() => shuffleMeme()}>Shuffle {shuffleIcon}</button>
        </div>
      </div>
  </div>
  )
}

export default MemeRaids
